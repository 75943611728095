import React, { useEffect, useState } from "react";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";

// indexDb
const getLocalStorage = () => {
  let tasks = localStorage.getItem("tasks");
  if (tasks) {
    return JSON.parse(tasks) as { text: string; completed: boolean }[];
  } else {
    return [];
  }
};

// todoList
const TodoList = () => {
  const [tasks, setTasks] = useState<{ text: string; completed: boolean }[]>(
    getLocalStorage()
  );
  const [newTask, setNewTask] = useState<string>("");

  useEffect(() => {
    localStorage.setItem("tasks", JSON.stringify(tasks));
  }, [tasks]);

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setNewTask(event.target.value);
  }

  function handleKeyEnter(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      addTask();
    }
  }

  function addTask() {
    if (newTask.trim() !== "") {
      setTasks([...tasks, { text: newTask, completed: false }]);
      setNewTask("");
    }
  }

  function checkTaskCompletion(index: number) {
    const updatedTasks = tasks.map((task, i) =>
      i === index ? { ...task, completed: !task.completed } : task
    );
    setTasks(updatedTasks);
  }

  function deleteTask(index: number) {
    const updatedTasks = tasks.filter((_, i) => i !== index);
    setTasks(updatedTasks);
  }

  return (
    <div className="Todo-section">
      <div className="todo-contents">
        <div className="todo-header">
          <h3>Tasks</h3>
        </div>

        <div className="task-display">
          {tasks.map((task, index) => (
            <div key={index} className="task-item">
              <div className="task-contents">
                <div className="task-buttons">
                  {task.completed ? (
                    <FaCheckCircle
                      onClick={() => checkTaskCompletion(index)}
                    />
                  ) : (
                    <FaRegCircle onClick={() => checkTaskCompletion(index)} />
                  )}
                </div>
                <span
                  className="task-text"
                  style={{
                    textDecoration: task.completed ? "line-through" : "none",
                  }}
                >
                  {task.text}
                </span>
              </div>
              <button className="task-delete-btn" onClick={() => deleteTask(index)}>
                Delete
              </button>
            </div>
          ))}
        </div>

        <div className="add-task-section">
          <div className="add-task-content">
            <input
              value={newTask}
              onChange={handleInputChange}
              onKeyDown={handleKeyEnter}
              className="task-input"
              placeholder="Enter new task..."
              type="text"
            />
            <button className="add-btn" onClick={addTask}>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TodoList;
